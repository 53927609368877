import React, { Component } from 'react';
import './style.css';
import NotificationChild from './NotificationChild';
import { Transition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetAllNotificationsData } from '../../ReduxActions/userActions';
import PermissionCheck from '../../HOC/PermissionCheck';
import Permissions from '../../Contexts/Permissions';
import { RadioButtons } from '../../Components/form-elements';
import {
  FilterOperation,
  OrderBy,
  RespondedFilter,
  SeenFilter
} from '../../Constants';
import { createFilterBy, createOrderBy } from '../../Helper/commons';

export const PAGE_SIZE = 20;

class Notification extends Component {
  constructor(state) {
    super(state);
    this.state = {
      Animation: false,
      lastPage: undefined,
      seenFilter: SeenFilter.ALL,
      respondedFilter: RespondedFilter.ALL
    };
  }
  componentDidMount() {
    this.apiRequest({ resetNotifications: true });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.NotificationsCount < this.props.NotificationsCount &&
      prevProps.NotificationsCount !== 0
    ) {
      this.fetchNewNotifications(
        this.props.NotificationsCount - prevProps.NotificationsCount
      );
    }
  }

  fetchNewNotifications(difference) {
    // how many pages must be fetched to receive all new notifications
    const pageCountToFetch = Math.ceil(difference / PAGE_SIZE);

    // do not send more than 5 page size (diff could be hundreds if user stayed afk too much)
    if (pageCountToFetch > 5) {
      this.apiRequest({ resetNotifications: true });
      return;
    }

    for (let pageIndex = 1; pageIndex <= pageCountToFetch; pageIndex++) {
      this.apiRequest({ pageIndex });
    }
  }

  apiRequest({ lastItem, resetNotifications, pageIndex }) {
    if (this.props.Notifications.pending) return;

    // send request if resetNotifications is true or notification list is not finished yet
    if (!(resetNotifications || !this.props.Notifications.atLast)) return;

    const filters = [];

    if (this.state.seenFilter !== SeenFilter.ALL) {
      filters.push(
        createFilterBy(
          'Seen',
          FilterOperation.Equal,
          this.state.seenFilter === SeenFilter.SEEN ? '1' : '0'
        )
      );
    }

    if (this.state.respondedFilter !== RespondedFilter.ALL) {
      filters.push(
        createFilterBy(
          'Responded',
          FilterOperation.Equal,
          this.state.respondedFilter === RespondedFilter.RESPONDED ? '1' : '0'
        )
      );
    }

    let finalPageIndex = 1;

    if (typeof pageIndex === 'number') {
      finalPageIndex = pageIndex;
    } else if (lastItem && !resetNotifications) {
      finalPageIndex = lastItem.PageIndex + 1;
    }

    this.props.getNotifications(
      {
        OrderBy: createOrderBy('CreateDate', OrderBy.DESC),
        PageIndex: finalPageIndex,
        PageSize: PAGE_SIZE,
        FilterBy: filters
      },
      resetNotifications,
      this.props.NotificationsCount
    );
  }

  cancel() {
    this.props.resetAllNotificationsData();
    this.setState({
      Animation: true
    });
    setTimeout(() => {
      this.setState({
        Animation: false
      });
      this.props.cancel();
    }, 800);
  }

  handleSeenFilterChange(value) {
    if (value === this.state.seenFilter) return;

    this.setState({ seenFilter: value }, () => {
      this.apiRequest({ resetNotifications: true });
    });
  }

  handleRespondedFilterChange(value) {
    if (value === this.state.respondedFilter) return;

    this.setState({ respondedFilter: value }, () => {
      this.apiRequest({ resetNotifications: true });
    });
  }

  render() {
    return (
      <Transition in={true} timeout={1000} unmountOnExit>
        <div className="Notification_wrapper">
          {this.props.Modal && (
            <div className="Overlay" onClick={this.cancel.bind(this)} />
          )}
          <div
            className={` ${
              this.props.Modal
                ? this.state.Animation
                  ? 'Notification_container_Remove'
                  : 'Notification_container'
                : this.props.className
            }`}
          >
            {this.props.showFilters && (
              <div className="notification-list-filters">
                <div className="notification-list-filter">
                  <FormattedMessage id="notificationFilter.seenFilter" />
                  <RadioButtons
                    className="notification-radio-buttons"
                    value={this.state.seenFilter}
                    onChange={this.handleSeenFilterChange.bind(this)}
                    isDisabled={this.props.Notifications.pending}
                    options={[
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.seen" />
                        ),
                        value: SeenFilter.SEEN
                      },
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.nonSeen" />
                        ),
                        value: SeenFilter.NON_SEEN
                      },
                      {
                        label: <FormattedMessage id="notificationFilter.all" />,
                        value: SeenFilter.ALL
                      }
                    ]}
                  />
                </div>

                <div className="notification-list-filter">
                  <FormattedMessage id="notificationFilter.respondedFilter" />
                  <RadioButtons
                    className="notification-radio-buttons"
                    value={this.state.respondedFilter}
                    onChange={this.handleRespondedFilterChange.bind(this)}
                    isDisabled={this.props.Notifications.pending}
                    options={[
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.responded" />
                        ),
                        value: RespondedFilter.RESPONDED
                      },
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.nonResponded" />
                        ),
                        value: RespondedFilter.NON_RESPONDED
                      },
                      {
                        label: <FormattedMessage id="notificationFilter.all" />,
                        value: RespondedFilter.ALL
                      }
                    ]}
                  />
                </div>
              </div>
            )}

            <NotificationChild
              maxHeight={this.props.maxHeight}
              data={this.props.Notifications.data}
              newReqest={this.apiRequest.bind(this)}
              loading={this.props.Notifications.pending}
              atLastNotification={this.props.Notifications.atLast}
            />
            {this.props.Modal && (
              <div className="Notification_footer">
                <PermissionCheck requiredRole={Permissions.PAGE_NOTIFICATIONS}>
                  <Link
                    className="Notification_seeAll"
                    onClick={this.cancel.bind(this)}
                    to={`/Notifications${this.props.urlParamsSearchString}`}
                  >
                    <FormattedMessage id="info.SeeAll" />
                  </Link>
                </PermissionCheck>
              </div>
            )}
          </div>
        </div>
      </Transition>
    );
  }
}

const mapStateToProps = state => {
  return {
    urlParamsSearchString: state.page.urlParamsSearchString,
    NotificationsCount: state.user.NotificationsCount
  };
};

const mapDispatchToProps = dispatchEvent => ({
  resetAllNotificationsData: () => dispatchEvent(resetAllNotificationsData())
});

Notification.defaultProps = {
  showFilters: true
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
