import React, { Component } from 'react';
import './style.css';

export default class ULoading extends Component {
  render() {
    return (
      <div
        className={`Loading__Wrapper ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <div className="Loading__Icon">
          <img
            src={process.env.PUBLIC_URL + '/loader.gif'}
            alt="loading..."
            style={{ width: 100, height: 100 }}
          />
        </div>
      </div>
    );
  }
}
